import enUS from "@assets/locales/en-US.json";
import zhCN from "@assets/locales/zh-CN.json";
import zhTW from "@assets/locales/zh-TW.json";
import es from "@assets/locales/es.json";
import ar from "@assets/locales/ar.json";
import hi from "@assets/locales/hi.json";
import pt from "@assets/locales/pt.json";
import fr from "@assets/locales/fr.json";
import ja from "@assets/locales/ja.json";
import ru from "@assets/locales/ru.json";
import de from "@assets/locales/de.json";
import id from "@assets/locales/id.json";
import bn from "@assets/locales/bn.json";
import vi from "@assets/locales/vi.json";
import ko from "@assets/locales/ko.json";
import tr from "@assets/locales/tr.json";
import it from "@assets/locales/it.json";
import pl from "@assets/locales/pl.json";
import th from "@assets/locales/th.json";
import nl from "@assets/locales/nl.json";
import fa from "@assets/locales/fa.json";

export interface LocaleItem {
  label: string;
  value: string;
  messages: any;
}
export const LOCALES_LIST: Array<LocaleItem> = [
  {
    label: "English",
    value: "en-US",
    messages: enUS,
  },
  {
    label: "简体中文",
    value: "zh-CN",
    messages: zhCN,
  },
  {
    label: "繁體中文",
    value: "zh-TW",
    messages: zhTW,
  },
  {
    label: "Spanish",
    value: "es",
    messages: es,
  },
  {
    label: "Arabic",
    value: "ar",
    messages: ar,
  },
  {
    label: "Hindi/Urdu",
    value: "hi",
    messages: hi,
  },
  {
    label: "Portuguese",
    value: "pt",
    messages: pt,
  },
  {
    label: "français",
    value: "fr-FR",
    messages: fr,
  },
  {
    label: "日本語",
    value: "ja",
    messages: ja,
  },
  {
    label: "Russian",
    value: "ru",
    messages: ru,
  },
  {
    label: "German",
    value: "de",
    messages: de,
  },
  {
    label: "Indonesian/Malay",
    value: "id",
    messages: id,
  },
  {
    label: "Bengali",
    value: "bn",
    messages: bn,
  },
  {
    label: "Vietnamese",
    value: "vi",
    messages: vi,
  },
  {
    label: "Korean",
    value: "ko",
    messages: ko,
  },
  {
    label: "Turkish",
    value: "tr",
    messages: tr,
  },
  {
    label: "Italian",
    value: "it",
    messages: it,
  },
  {
    label: "Polish",
    value: "pl",
    messages: pl,
  },
  {
    label: "Thai",
    value: "th",
    messages: th,
  },
  {
    label: "Dutch",
    value: "nl",
    messages: nl,
  },
  {
    label: "Persian",
    value: "fa",
    messages: fa,
  }
];

const transArr2Map = (array: Array<any>, key: string, value: string) => {
  const map: Record<string, string> = {};
  array.forEach((item) => {
    map[item[key]] = item[value];
  });
  return map;
};
export const LOCALE_DATA = transArr2Map(LOCALES_LIST, "value", "messages");
export const LOCALE_LABEL = transArr2Map(LOCALES_LIST, "value", "label");

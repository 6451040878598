import styles from './index.module.scss';
import intl from 'react-intl-universal';
const DefaultIcon = () => {
  return (
    <div className={styles['default_icon']}>
      <div className={styles.point}></div>
    </div>
  );
};
const Loading = (props: {
  fontSize?: number;
  justifyContent?: string;
  background?: string;
  icon?: any;
  text?: string;
  textStyle?: any;
  showText?: boolean;
}) => {
  const { fontSize = 50, background = '#fff', icon, textStyle, showText } = props;
  let text = props.text;
  if(showText && !text) {
    text = intl.get('SPLAYER.LOADING')
  }
  return (
    <div
      className={styles.loading}
      style={{
        background: `${background}`,
      }}
    >
      <div style={{ textAlign: 'center' }}>
        {icon ? (
          <div
            className={styles['custom_icon']}
            style={{
              width: fontSize,
              height: fontSize,
            }}
          >
            {icon}
          </div>
        ) : (
          <DefaultIcon />
        )}
        {text && (
          <div className={styles.text} style={textStyle}>
            {text}
          </div>
        )}
      </div>
    </div>
  );
};

export default Loading;

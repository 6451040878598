const PRO_API = {
  ether: "https://api.etherscan.io",
  exchangeUrl: "https://api.1inch.io/v5.0/1",
  authUrl: "https://im.fc.plus/demo",
  nftUrl: "https://blindbox.fc.plus/api",
  pledgesUrl: "https://pledges.fc.plus",
  imUrl: 'https://im.fc.plus/api',
  blockdata: "https://blockchaindata.fc.plus/api",
  fissionUrl: "https://im.fc.plus/freechat-api",
  tokenListUrl: "https://vfcc.fc.plus/api",
  shareUrl: "https://share.fc.plus/",
  appUrl: "https://app.fc.plus/"
};

const DEV_API = {
  ether: 'https://api.etherscan.io',
  exchangeUrl: 'https://api.1inch.io/v5.0/1',
  authUrl: 'https://imtest.fc.plus/demo',
  imUrl: 'https://imtest.fc.plus/api',
  nftUrl: 'https://blindbox-test.fc.plus/api',
  pledgesUrl: 'https://pledges-test.fc.plus',
  blockdata: 'https://blockchaindata-test.fc.plus/api',
  fissionUrl: 'https://imtest.fc.plus/freechat-api',
  tokenListUrl: 'https://vfcc-test.fc.plus/api',
  shareUrl: 'https://share-test.fc.plus/',
  appUrl: "https://app-test.fc.plus/"

};

// 测试使用"dev",生产使用"pro" - 默认
enum MODE {
 DEV = "development",
 PRO = "production"
}

const CONFIG = Object.assign(
  {
    metaTitle: 'Free-Chat',
    tokenSymbol: 'FCC',
  },
  process.env.NODE_ENV === MODE.DEV ? PRO_API : PRO_API
);

export interface TokenProps {
  symbol: string;
  Name: string;
  Address: string;
  Abi?: string;
  Decimals: number;
  LogoURI: string;
  img: string;
  website: string;
  description: string;
  contractAddress: string;
  totalSupply: string;
  github: string;
  twitter: string;
  facebook: string;
  reddit: string;
  balance: number;
}

export interface InchTokenProps {
  address: string;
  decimals: number;
  isCustom: boolean;
  isFOT: boolean;
  logoURI: string;
  name: string;
  symbol: string;
}

export interface QuoteResponse {
  estimatedGas: number;
  fromToken: InchTokenProps;
  fromTokenAmount: string;
  protocols: [];
  toToken: InchTokenProps;
  toTokenAmount: string;
}

export default CONFIG;

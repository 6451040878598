declare var window: Window & { webkit: any; native: any; invokeRes: any };

const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
export const invokeApp = (fn, param, cb) => {
  console.log('invokeApp', fn, param, cb);
  let appObj = window.native;
  if (isIOS) {
    appObj = window.webkit?.messageHandlers;
  }
  console.log('isIOS-->', isIOS);
  console.log('appObj-->', appObj);
  if (!appObj) {
    console.log(`could not get appObj for ${fn}:`, appObj);
    return;
  }
  console.log('get appObj[fn] success', fn, appObj[fn]);
  let cbFn = `invokeRes_${fn}_${new Date().getTime()}`;
  window[cbFn] = res => {
    cb(res);
  };
  if (isIOS) {
    appObj[fn] && appObj[fn].postMessage({ param: param, callback: cbFn });
  } else {
    appObj[fn] &&
      appObj[fn](JSON.stringify({ param: param, callback: cbFn }));
  }
};

import AES from 'crypto-js/aes';
import JSEncrypt from 'jsencrypt';
import { SERVER_PUBLIC_KEY } from '@/config/constants';
import { LOCALES_LIST } from '@/components/const';
import { Toast } from 'antd-mobile';
import intl from 'react-intl-universal';
import ClipboardJS from 'clipboard';

const CryptoJS = require('crypto-js'); //引用AES源码js
/**
 * @description: 自定义方法
 * @return {*}
 */

// local store
class Local {
  set<T>(key: string, val: T) {
    const data = JSON.stringify(val);
    window.localStorage.setItem(key, data);
  }
  get(key: string) {
    const data = JSON.parse(window.localStorage.getItem(key) || 'null');
    return data;
  }
  remove(key: string) {
    window.localStorage.removeItem(key);
  }
  clear() {
    window.localStorage.clear();
  }
}
const local = new Local();

class Session {
  set<T>(key: string, val: T) {
    const data = JSON.stringify(val);
    window.sessionStorage.setItem(key, data);
  }
  get(key: string) {
    const data = JSON.parse(window.sessionStorage.getItem(key) || 'null');
    return data;
  }
  remove(key: string) {
    window.sessionStorage.removeItem(key);
  }
  clear() {
    window.sessionStorage.clear();
  }
}

const session = new Session();

// 防抖
const debounce = (fn: Function, time: number, thisArg?: any) => {
  let timeout: any = null;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(thisArg, args);
    }, time);
  };
};
// 节流
const throttle = (fn: Function, time: number, thisArg?: any) => {
  let start = true;
  return function (...args: any[]) {
    if (!start) return;
    start = false;
    setTimeout(() => {
      fn.apply(thisArg, args);
      start = true;
    }, time);
  };
};

const GetDevice = () => {
  /* 版本信息获取 */
  // 各主流浏览器
  let getBrowser = function () {
    let u = navigator.userAgent;
    let bws = [
      {
        name: 'sgssapp',
        it: /sogousearch/i.test(u),
      },
      {
        name: 'wechat',
        it: /MicroMessenger/i.test(u),
      },
      {
        name: 'weibo',
        it: !!u.match(/Weibo/i),
      },
      {
        name: 'uc',
        it: !!u.match(/UCBrowser/i) || u.indexOf(' UBrowser') > -1,
      },
      {
        name: 'sogou',
        it: u.indexOf('MetaSr') > -1 || u.indexOf('Sogou') > -1,
      },
      {
        name: 'xiaomi',
        it: u.indexOf('MiuiBrowser') > -1,
      },
      {
        name: 'baidu',
        it: u.indexOf('Baidu') > -1 || u.indexOf('BIDUBrowser') > -1,
      },
      {
        name: '360',
        it: u.indexOf('360EE') > -1 || u.indexOf('360SE') > -1,
      },
      {
        name: '2345',
        it: u.indexOf('2345Explorer') > -1,
      },
      {
        name: 'edge',
        it: u.indexOf('Edge') > -1,
      },
      {
        name: 'ie11',
        it: u.indexOf('Trident') > -1 && u.indexOf('rv:11.0') > -1,
      },
      {
        name: 'ie',
        it: u.indexOf('compatible') > -1 && u.indexOf('MSIE') > -1,
      },
      {
        name: 'firefox',
        it: u.indexOf('Firefox') > -1,
      },
      {
        name: 'safari',
        it: u.indexOf('Safari') > -1 && u.indexOf('Chrome') === -1,
      },
      {
        name: 'qqbrowser',
        it: u.indexOf('MQQBrowser') > -1 && u.indexOf(' QQ') === -1,
      },
      {
        name: 'qq',
        it: u.indexOf('QQ') > -1,
      },
      {
        name: 'chrome',
        it: u.indexOf('Chrome') > -1 || u.indexOf('CriOS') > -1,
      },
      {
        name: 'opera',
        it: u.indexOf('Opera') > -1 || u.indexOf('OPR') > -1,
      },
    ];
    for (let i = 0; i < bws.length; i++) {
      if (bws[i].it) {
        return bws[i].name;
      }
    }

    return 'other';
  };
  // 系统区分
  let getOS = function () {
    let u = navigator.userAgent;
    if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
      return 'windows';
    } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
      return 'macOS';
    } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
      return 'ios';
    } else if (u.match(/android/i)) {
      return 'android';
    } else if (u.match(/Ubuntu/i)) {
      return 'Ubuntu';
    } else {
      return 'other';
    }
  };

  const os = getOS();
  const model = getBrowser();
  return { os: os, dev: model };
};

const toThousands = (num = 0, decimal?: number) => {
  let temp = noExponents(num || 0);
  if (temp.indexOf('.') === -1) {
    return temp.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  } else {
    let intgerPart = temp.split('.')[0];
    let decimalPart = temp.split('.')[1];
    if (!decimal) {
      decimalPart = decimalPart.slice(0, 2);
    } else {
      decimalPart = decimalPart.slice(0, decimal);
    }
    return `${intgerPart.replace(
      /(\d)(?=(?:\d{3})+$)/g,
      '$1,'
    )}.${decimalPart}`;
  }
};
// 数字转字符串（含科学计数法格式的数字）
const noExponents = (exponent: number) => {
  const data = String(exponent).split(/[eE]/);
  if (data.length === 1) return data[0];
  let z = '',
    sign = exponent < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;
  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
};

// 验证邮箱合法
const verifyEmailLegal = (email: string) => {
  // verify email legal
  let emailRegExp = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; //验证邮箱的正则表达式
  let emailLegal = emailRegExp.test(email); //验证是否符合要求
  return emailLegal;
};

const encryptedmailbox = email => {
  if (String(email).indexOf('@') > 0) {
    let str = email.split('@');
    let _s = '';
    let new_email = '';

    if (str[0].length > 3) {
      //@前面多于3位
      for (let i = 3; i < str[0].length; i++) {
        _s += '*';
      }
      new_email = str[0].substr(0, 3) + _s + '@' + str[1];
    } else {
      //@前面小于等于于3位
      for (let i = 1; i < str[0].length; i++) {
        _s += '*';
      }
      new_email = str[0].substr(0, 1) + _s + '@' + str[1];
    }
    return new_email;
  }
  return '';
};

const toFixed = (val: string | number, decimal?: number) => {
  if (decimal) {
    return `${Math.round(Number(val) * 10 ** decimal) / 10 ** decimal}`;
  }
  return `${Math.round(Number(val) * 1000000) / 1000000}`;
};

const checkInputNum = (
  value: string | number,
  digits: number,
  integerMax?: number
) => {
  let digitExp = /^([\d]+\.)?[\d]*$/;
  let val = '';
  if (Object.prototype.toString.call(value).indexOf('Number') !== -1) {
    val = noExponents(<number>value);
  } else {
    val = <string>value;
  }
  if (digitExp.test(val)) {
    if (Number(val) >= 9007199254740991) {
      // Number 方法会对9007199254740991.xxxx的小数截断
      return '9007199254740991';
    } else {
      let reg;
      if (integerMax) {
        reg = new RegExp(`^(\\-)*(\\d{${integerMax}}).*$`); // 通过 integerMax 限制整数位,
        if (!val.includes('.')) {
          val = val.replace(reg, '$1$2');
        }
      }
      if (digits) {
        reg = new RegExp(`^(-)*(\\d*)\\.(\\d{0,${digits}}).*$`); // 通过 digits 限制小数位,
        val = val.replace(reg, '$1$2.$3');
      }
      val = val.replace(reg, '$1$2.$3');
      return val;
    }
  } else {
    return val;
  }
};

const getUrlParams = (url: string, key?: string): {} | string => {
  let params = url.split('?')[1];
  let obj = {};
  if (params) {
    let paramsArr = params.split('&');
    for (let index = 0; index < paramsArr.length; index++) {
      const element = paramsArr[index].split('=');
      obj[element[0]] = element[1];
    }
  }
  if (key) {
    return obj[key];
  }
  return obj;
};

const getRandomID = () => {
  let date = Date.now();
  let rund = Math.ceil(Math.random() * 1000);
  let id = date + '' + rund;
  return id;
};

// 生成随机apikey
function generateKey(digits: number = 16) {
  let key = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < digits; i++) {
    key += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return key;
}

// rsaDecrypt加密
function rsaDecrypt(apikey) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(SERVER_PUBLIC_KEY);
  return encryptor.encrypt(apikey);
}

//解密方法
function Decrypt(word, k, i) {
  let key = CryptoJS.enc.Utf8.parse(k); //十六位十六进制数作为密钥
  let iv = CryptoJS.enc.Utf8.parse(i); //十六位十六进制数作为密钥偏移量
  let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

//加密方法
function Encrypt(word, k, i) {
  let key = CryptoJS.enc.Utf8.parse(k); //十六位十六进制数作为密钥
  let iv = CryptoJS.enc.Utf8.parse(i); //十六位十六进制数作为密钥偏移量
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString().toUpperCase();
}

//base64加密
function base64_encode(code) {
  var str = CryptoJS.enc.Utf8.parse(code);
  return CryptoJS.enc.Base64.stringify(str);
}

//base64解密
function base64_decode(code) {
  var words = CryptoJS.enc.Base64.parse(code);
  return words.toString(CryptoJS.enc.Utf8);
}

//解密data方法
function DecryptData(cryptedContent: string, key: string) {
  // 确定解密base64
  let encryptedHexStr = base64_decode(cryptedContent);
  let iv = encryptedHexStr.slice(0, 16);

  // 待解密数据解析
  let val = encryptedHexStr.substring(16);

  // 处理成cryptojs格式
  const cryptoKey = CryptoJS.enc.Utf8.parse(key);
  const cryptoIv = CryptoJS.enc.Utf8.parse(iv);

  const decrypt = CryptoJS.AES.decrypt(val, cryptoKey, {
    iv: cryptoIv,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding,
  });

  const resultContent = decrypt.toString(CryptoJS.enc.Utf8);

  return resultContent;
}

//加密data方法
function EncryptData(word: Object, key: string) {
  const c = `${word}`;

  // 处理得到iv
  const hashStr = CryptoJS.MD5(c).toString();
  let iv = hashStr.slice(0, 16);

  // 处理成cryptojs格式
  const cryptoKey = CryptoJS.enc.Utf8.parse(key);
  const cryptoIv = CryptoJS.enc.Utf8.parse(iv);

  // 加密
  const encrptoStr = AES.encrypt(c, cryptoKey, {
    iv: cryptoIv,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding,
  });

  const stringEncrptoStr = encrptoStr.toString();
  const combined = iv + stringEncrptoStr;

  const base64Combined = base64_encode(combined);
  return base64Combined;
}

const handleH5Lang = (lang: string) => {
  let currentLocale = navigator.language;
  if (lang === 'zh-TW' || lang === 'zh-Hant-TW') {
    currentLocale = 'zh-TW';
  } else if (lang?.includes('zh')) {
    currentLocale = 'zh-CN';
  } else if (lang?.includes('pt')) {
    currentLocale = 'pt';
  } else if (!!lang) {
    currentLocale = lang;
  }
  if (!LOCALES_LIST.some((item) => item.value === currentLocale)) {
    currentLocale = "en-US";
  }
  return currentLocale;
};

/**
 * 把图片处理成圆角矩形,如果不是正方形就按最小边为边长处理成正方形
 * @param  {object} img 图片(img)对象
 * @param  {object} rad 矩形圆角，当为小数时，默认为矩形短边的百分比，设为50%则结果为圆形
 * @return {string}     return canvas
 */
const roundRectClipImg = (img, chamferRad) => {
  let x = 0;
  let y = 0;
  let sideLen = 0;
  let rad = 0;
  let canvas = document.createElement('canvas');
  if (!canvas.getContext) {
    // 判断浏览器是否支持canvas，如果不支持在此处做相应的提示
    console.log('您的浏览器版本过低，暂不支持。');
    return img;
  }
  if (img.naturalWidth > img.naturalHeight) {
    sideLen = img.naturalHeight;
    x = (img.naturalWidth - img.naturalHeight) / 2;
  } else {
    sideLen = img.naturalWidth;
    y = (img.naturalHeight - img.naturalWidth) / 2;
  }
  if (typeof +chamferRad === 'number' && !isNaN(+chamferRad)) {
    rad = (chamferRad * sideLen) / 201;
  } else if (chamferRad.indexOf && chamferRad.indexOf('%') !== -1) {
    rad = (parseFloat(chamferRad) * sideLen) / 100;
  }
  canvas.width = sideLen;
  canvas.height = sideLen;
  let context = canvas.getContext('2d');
  context!.clearRect(0, 0, sideLen, sideLen);
  context!.save();
  context!.beginPath();
  context!.moveTo(rad, 0);
  context!.arcTo(sideLen, 0, sideLen, sideLen, rad);
  context!.arcTo(sideLen, sideLen, 0, sideLen, rad);
  context!.arcTo(0, sideLen, 0, 0, rad);
  context!.arcTo(0, 0, sideLen, 0, rad);
  context!.clip();
  context!.drawImage(img, x, y, sideLen, sideLen, 0, 0, sideLen, sideLen);
  context!.restore();
  return canvas;
};

// 获取querystring
function getQueryStringParams() {
  var queryString = window.location.hash || window.location.search;
  var queryParams = {};
  if (queryString) {
    queryString.split('?')[1] && (queryString = queryString.split('?')[1]);
    var params = queryString.split('&');
    for (var i = 0; i < params.length; i++) {
      var param = params[i].split('=');
      var paramName = decodeURIComponent(param[0]);
      var paramValue = decodeURIComponent(param[1]);
      queryParams[paramName] = paramValue;
    }
  }
  return queryParams;
}

function compareVersions(v1: string, v2: string) {
  const v1Arr = v1.split('.');
  const v2Arr = v2.split('.');
  const len = Math.max(v1Arr.length, v2Arr.length);
  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1Arr[i] || '0');
    const num2 = parseInt(v2Arr[i] || '0');
    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }
  return 0;
}

// 判断是否可以parse
function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

function goToExchange() {
  let address = getUrlParams(location.href, 'address');
  let lang = handleH5Lang(getUrlParams(location.href, 'lang') as string);
  let isUnit = getUrlParams(location.href, 'isUnit') as string;
  let group = getUrlParams(location.href, 'group') as string;
  location.href = `${location.protocol}//${location.host}/#/miniapp/exchange?address=${address}&lang=${lang}&isUnit=${isUnit}&group=${group}`;
}

const copy = text => {
  const fake_el = document.createElement('button');
  const clipboard = new ClipboardJS(fake_el, {
    text: function () {
      return text;
    },
    action: function () {
      return 'copy';
    },
    container: document.body,
  });
  clipboard.on('success', function (e) {
    Toast.show(intl.get('COPY_SUCCESS'));
    clipboard.destroy();
  });
  clipboard.on('error', function (e) {
    clipboard.destroy();
  });
  fake_el.click();
};

const numMulti = (num1, num2) => {
  let baseNum = 0;
  try {
    baseNum += num1.toString().split('.')[1].length;
  } catch (e) {}
  try {
    baseNum += num2.toString().split('.')[1].length;
  } catch (e) {}
  return (
    (Number(num1.toString().replace('.', '')) *
      Number(num2.toString().replace('.', ''))) /
    Math.pow(10, baseNum)
  );
};

const numAdd = (num1, num2) => {
  let baseNum, baseNum1, baseNum2;
  try {
    baseNum1 = num1.toString().split('.')[1].length;
  } catch (e) {
    baseNum1 = 0;
  }
  try {
    baseNum2 = num2.toString().split('.')[1].length;
  } catch (e) {
    baseNum2 = 0;
  }
  baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
  return (num1 * baseNum + num2 * baseNum) / baseNum;
};

const formatNumberToBritish = (number: string|number) => {
  return Number(number).toLocaleString('en-GB');
}

export {
  goToExchange,
  session,
  local,
  debounce,
  throttle,
  GetDevice,
  getRandomID,
  getUrlParams,
  toThousands,
  toFixed,
  checkInputNum,
  noExponents,
  verifyEmailLegal,
  encryptedmailbox,
  handleH5Lang,
  Decrypt,
  Encrypt,
  roundRectClipImg,
  getQueryStringParams,
  compareVersions,
  generateKey,
  DecryptData,
  EncryptData,
  rsaDecrypt,
  isValidJSON,
  copy,
  numAdd,
  numMulti,
  formatNumberToBritish
};

import { isAndroid } from '@/utils/ua';
import CallApp from 'callapp-lib';
const option = {
  scheme: {
    protocol: 'freechatworld',
    host: 'share.fc.plus',
  },
  intent: {
    package: 'com.freechatworld.freechat',
    scheme: 'freechatworld',
  },
  // universal: {
  //   host: "share.fc.plus",
  //   pathKey: "",
  // },
  appstore: 'https://apps.apple.com/us/app/freechat/id1660829770',
  fallback: 'https://www.fc.plus',
  timeout: 2000,
};
const lib = new CallApp(option);

export const downLoadApp = () => {
    if (isAndroid) {
        if (navigator.language.includes('zh')) {
          location.href =
            'https://oss.fc.plus/apk/freechat-freechatonline-release.apk';
        } else {
          location.href =
            'https://play.google.com/store/apps/details?id=com.freechatworld.freechat';
        }
      } else {
        const config = {
          path: '',
          param: {
            pageType: 'invite',
          },
        };
        lib.open(config);
      }
}
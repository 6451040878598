import { Outlet } from 'react-router-dom';
import AppRouter from './router/appRouter';
import { useEffect, useState } from 'react';
import { LOCALES_LIST, LOCALE_DATA } from './components/const';
import VConsole from 'vconsole';
import { local, handleH5Lang, getQueryStringParams } from './utils/fun';
import { isLocalDev } from '@/utils/const';
import { login } from '@/api/index';
import { isIOS, invokeApp } from '@utils/index';
import storage from '@/utils/storage';
import accessWhitelist from '@/config/accessWhitelist';
import Loading from '@/components/Loading';

import 'antd/dist/antd.css';
import './assets/scss/global.scss';
import './assets/scss/antd.scss';
import './assets/scss/antd-mobile.scss';
import intl from 'react-intl-universal';

if (
  (!isLocalDev && location.hostname === 'app-test.fc.plus') ||
  location.href?.includes('debug')
) {
  new VConsole();
}
function App() {
  const [initDone, setInitDone] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState('...');
  const [loadingApiKey, setLoadingApiKey] = useState<boolean>(true);
  const [loadingToken, setLoadingToken] = useState<boolean>(true);
  const { address, lang } = getQueryStringParams() as any;

  useEffect(() => {
    if (!(loadingApiKey || loadingToken)) {
      setLoadingTxt('');
      setInitDone(true);
    }
    if (!loadingApiKey && loadingToken) {
      setLoadingTxt('..');
    }
    if (loadingApiKey && !loadingToken) {
      setLoadingTxt('.');
    }
  }, [loadingApiKey, loadingToken]);

  const initAccess = () => {
    console.log('initAccess');
    // 本地开发环境
    if (accessWhitelist.findIndex(item => location.href.includes(item)) >= 0) {
      console.log('accessWhitelist');
      setLoadingApiKey(false);
      setLoadingToken(false);
      return;
    } else {
      if (isLocalDev) {
        if (!address) {
          alert('请在地址栏添加参数：?address=xxx');
        }
        login(address).then(res => {
          local.set('token', res?.data?.token);
          setLoadingApiKey(false);
          setLoadingToken(false);
        });
      } else {
        console.log('invokeApp-getApikey', invokeApp);
        try {
          invokeApp('getApikey', null, res => {
            // 获取端上请求数据加密的key，注意：platform需要与对应的key绑定
            console.log('getApikey res:', res)
            if (res?.status === '0') {
              local.set('apikey', res.apikey);
              local.set('platform', isIOS ? 1 : 2);
              setLoadingApiKey(false);
            } else {
              console.log('获取apikey失败, res?.status = ', res?.status);
            }
          });
        } catch (error) {
          console.log('getApikey->error', error);
        }

        console.log('invokeApp-getLoginToken');
        invokeApp('getLoginToken', null, res => {
          console.log('getLoginToken res:', res)
          if (res?.status === '0') {
            local.set('token', res.token);
            setLoadingToken(false);
          } else {
            console.log('获取token失败, res?.status = ', res?.status);
          }
        });
      }
    }
  };
  const initIntl = () => {
    // 初始化国际化
    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang', // Example: https://fe-tool.com/react-intl-universal?lang=en-US
      localStorageLocaleKey: 'lang',
    });
    if (!LOCALES_LIST.some(item => item.value === currentLocale)) {
      currentLocale = 'en-US';
    }
    intl.init({
      currentLocale: handleH5Lang(lang),
      locales: LOCALE_DATA,
    });
    storage.set('lang', handleH5Lang(lang));
  };

  useEffect(() => {
    initIntl();
    setTimeout(() => {
      initAccess();
    }, 0);
    // 过期注销
    // const token = local.get('token');
    // const expiredTime = local.get('expiredTime');
    // if (!token || new Date().getTime() > (expiredTime * 1000)) {
    //   dispatch(clearAll());
    // }
  }, []);
  console.log(loadingApiKey, loadingToken);
  if (!initDone)
    return (
      <div>
        <Loading text={loadingTxt} />
        <a>close</a>
      </div>
    );
  return (
    <div className='overview'>
      <AppRouter />
      <Outlet />
    </div>
  );
}

export default App;

import axios from '../utils/blockdata.request'
import { local, getRandomID } from '@/utils/fun';
import axiosf from '@utils/fission.request';

export const login = (userID) => {
  return axiosf.request({
    url: 'https://imtest.fc.plus/api/auth/user_token',
    method: 'POST',
    data: JSON.stringify({
      secret: 'freechat',
      platform: 2,
      userID,
      operationID: `${new Date().getTime()}`,
    }),
  });
};

// 是否群主

export const checkGroupOwner = data => {
  const token = local.get('token');
  return axiosf.request({
    url: `/group/check_group_owner`,
    method: 'GET',
    data: JSON.stringify(data),
    headers: {
      token,
      groupId: data.groupId
    }
  });
};
export const getCNY = () => {
  return axios.request({
    url: `v5/market/exchange-rate`,
    method: 'GET'
  })
}

export const indexTickers = (bi:string) => {
  return axios.request({
    url: `v5/market/index-tickers?instId=${bi}-USD`,
    method: 'GET'
  })
}

export const getTokenListJson = () => {
  return axios.request({
    url: `v5/market/tokenlist`,
    method: 'GET'
  })
}
